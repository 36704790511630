<script lang="ts" setup>
import type { ISbRichtext } from "@/types";

const props = defineProps<{ content: ISbRichtext }>();

const htmlContent = computed(() => renderRichText(props.content));
</script>

<template>
  <div v-if="htmlContent" class="rich-text" v-html="htmlContent" />
</template>
